import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
    PlaceDriverRole,
    PlaceModule,
    PlaceRepository,
    PlaceSystem,
    PlaceZone,
} from '@placeos/ts-client';
import { isBefore } from 'date-fns';
import { map } from 'rxjs/operators';
import { AsyncHandler } from '../common/async-handler.class';
import { nextValueFrom } from '../common/general';
import { ActiveItemService } from '../common/item.service';

@Component({
    selector: 'item-sidebar',
    template: `
        <div
            class="flex h-full w-[24rem] min-w-64 max-w-[25vw] flex-col space-y-2 overflow-hidden rounded border-base-200 bg-base-100 shadow sm:border-r"
            (click)="$event.stopPropagation()"
        >
            <div class="relative flex items-center border-b border-base-200">
                <app-icon
                    class="pointer-events-none absolute left-2 top-1/2 -translate-y-1/2 text-2xl"
                >
                    search
                </app-icon>
                <input
                    #search_input
                    class="bg-transparent flex-1 border-none py-4 pl-10 pr-4"
                    [(ngModel)]="search"
                    (ngModelChange)="updateSearch($event)"
                    [placeholder]="
                        'COMMON.SEARCH_FOR' | translate: { name: title }
                    "
                />
                <mat-spinner
                    *ngIf="loading | async"
                    diameter="24"
                    class="absolute right-2 top-1/2 mr-2 -translate-y-1/2"
                ></mat-spinner>
            </div>
            <p class="w-full px-2 text-sm opacity-60">
                {{
                    'COMMON.TOTAL_ITEMS' | translate: { count: (total | async) }
                }}
            </p>
            <div class="flex h-1/2 flex-1 flex-col">
                <cdk-virtual-scroll-viewport
                    no-x-scroll
                    itemSize="64"
                    (scroll)="(is_scrolled)"
                    (scrolledIndexChange)="atBottom()"
                    *ngIf="(items | async)?.length; else empty_state"
                    class="relative h-1/2 w-full flex-1"
                >
                    <a
                        *cdkVirtualFor="
                            let item of items | async;
                            trackBy: trackByFn
                        "
                        [routerLink]="
                            subroute
                                ? ['/', route, item.id, subroute]
                                : ['/', route, item.id]
                        "
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{
                            exact: false,
                            __change_detection_hack__: item.id + subroute,
                        }"
                        [matTooltip]="
                            item.update_available &&
                            item.commit !== item.update_info.commit
                                ? ('COMMON.UPDATE_AVAILABLE' | translate)
                                : ''
                        "
                        class="relative m-2 flex w-[23rem] max-w-[calc(100%-1rem)] flex-col rounded px-2 py-2"
                        (click)="show = false"
                    >
                        <p class="w-full truncate">
                            {{ item.name }}
                        </p>
                        <div class="inline-block w-full overflow-hidden">
                            <span
                                extra
                                class="mono bg-base-content/10 /5 mt-1 max-w-full truncate rounded px-2 py-1 text-xs opacity-60"
                                *ngIf="item.extra"
                            >
                                {{ item.extra }}
                            </span>
                        </div>
                        <app-icon
                            class="absolute -right-1 -top-1 rotate-12 text-2xl text-info"
                            *ngIf="
                                item.update_available &&
                                item.commit !== item.update_info.commit
                            "
                        >
                            new_releases
                        </app-icon>
                        <div
                            class="absolute -right-1 -top-1 flex h-8 w-8 rotate-12 items-center justify-center rounded-full bg-warning text-2xl text-warning-content"
                            *ngIf="item.zone_issues"
                            [matTooltip]="
                                (item.zone_issues === 'system'
                                    ? 'SYSTEMS.MISCONFIGURED'
                                    : 'ZONES.MISCONFIGURED'
                                ) | translate
                            "
                        >
                            <app-icon> brightness_alert </app-icon>
                        </div>
                        <div
                            class="absolute -right-1 -top-1 flex h-8 w-8 rotate-12 items-center justify-center rounded-full bg-error text-2xl text-error-content"
                            *ngIf="item.has_runtime_error"
                            [matTooltip]="'MODULES.ERROR' | translate"
                        >
                            <app-icon> error </app-icon>
                        </div>
                    </a>
                    <div class="bg-base-200 p-2 text-center text-sm opacity-30">
                        {{ 'COMMON.END_OF_LIST' | translate }}
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
        </div>
        <ng-template #empty_state>
            <div
                class="flex flex-col items-center justify-center p-8 opacity-30"
            >
                <p>
                    {{
                        (search ? 'COMMON.SEARCH_EMPTY' : 'COMMON.LIST_EMPTY')
                            | translate: { name: title }
                    }}
                </p>
            </div>
        </ng-template>
    `,
    styles: [
        `
            :host {
                height: 100%;
            }
            a:nth-child(2n) {
                background-color: var(--b2);
            }
            a:hover {
                background-color: var(--b3);
            }
            a.active {
                background-color: var(--s);
                color: var(--sc);
            }

            a:hover [extra] {
                background-color: var(--b2);
            }

            a [extra] {
                background-color: var(--b3);
            }

            a.active [extra] {
                background-color: var(--sf);
            }
        `,
    ],
    standalone: false,
})
export class ItemSidebarComponent extends AsyncHandler {
    @Input() public title = 'Systems';
    @Input() public route = 'systems';

    public last_total = 0;
    public last_check = 0;
    public search = '';
    /** List of items for the active route */
    public readonly items = this._service.list.pipe(
        map((l) => this._processItems(l)),
    );
    /** Whether list of items for the active route are loading */
    public readonly loading = this._service.loading_list;
    /** Total number of items in the last request */
    public total = this._service.count;

    /** Virtual scrolling viewport */
    @ViewChild(CdkVirtualScrollViewport)
    private viewport: CdkVirtualScrollViewport;

    @ViewChild('search_input') private _input: ElementRef<HTMLInputElement>;

    public get subroute() {
        return this._router.url.split('/')[3] || '';
    }

    constructor(
        private _router: Router,
        private _service: ActiveItemService,
    ) {
        super();
    }

    public ngAfterViewInit() {
        this.focusInput();
        this.atBottom();
    }

    public focusInput() {
        this._input?.nativeElement.focus();
    }

    public updateSearch(str: string) {
        this._service.setSearch(str);
    }

    public trackByFn(item: Record<string, any>, index: number) {
        return item.id || index;
    }

    /** Whether to update the list of items */
    public get is_stale() {
        const now = Date.now();
        const last_check = this.last_check;
        return (
            this.last_total !== this._service.list_items().length ||
            isBefore(now, last_check + 60 * 1000)
        );
    }

    /**
     * Check if user has scrolled to the bottom of the sidebar and emit an event to get next page of items
     */
    public async atBottom() {
        const loading = await nextValueFrom(this.loading);
        if (loading || !this.is_stale) return;
        if (!this.viewport) {
            return this.timeout('atBottom', () => this.atBottom());
        }
        const end = this.viewport.getRenderedRange().end;
        const total = this.viewport.getDataLength();
        if (end >= total - 1) {
            this.last_total = total;
            this.last_check = Date.now();
            if (this.last_total !== this._service.total) {
                this._service.moreItems();
            }
        }
    }

    private _processItems(list: any[]) {
        for (let item of list) {
            if (item instanceof PlaceModule) {
                const name = item.system?.display_name || item.system?.name;
                const detail =
                    item.role === PlaceDriverRole.Service
                        ? item.uri
                        : item.role === PlaceDriverRole.Logic
                          ? name
                              ? `${name} | ${item.control_system_id} `
                              : item.control_system_id
                          : item.ip;
                (item as any).display_name =
                    item.custom_name || item.name || '<Unnamed>';
                (item as any).extra = detail;
            } else if (item instanceof PlaceRepository) {
                (item as any).display_name = item.name || '<Unnamed>';
                (item as any).extra = item.repo_type;
            } else if (item instanceof PlaceSystem) {
                (item as any).display_name =
                    item.display_name || item.name || '<Unnamed>';
                (item as any).zone_issues =
                    (item.email || item.map_id) && item.zones.length < 3
                        ? 'system'
                        : '';
            } else if (item instanceof PlaceZone) {
                (item as any).display_name =
                    item.display_name || item.name || '<Unnamed>';
                (item as any).zone_issues =
                    (item.tags.includes('level') ||
                        item.tags.includes('building') ||
                        item.tags.includes('region')) &&
                    !item.parent_id
                        ? 'zone'
                        : '';
            } else {
                (item as any).display_name =
                    item.display_name ||
                    item.custom_name ||
                    item.name ||
                    '<Unnamed>';
                (item as any).extra = item.id;
            }
        }
        return list;
    }
}
