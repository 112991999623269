import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, Input, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PlaceZone, queryZones, showZone } from '@placeos/ts-client';

import { AsyncHandler } from 'apps/backoffice/src/app/common/async-handler.class';
import {
    addChipItem,
    removeChipItem,
} from 'apps/backoffice/src/app/common/forms';
import { map } from 'rxjs/operators';
import { TIMEZONES_IANA } from '../../common/timezones';

@Component({
    selector: 'zone-form',
    template: `
        <form zone *ngIf="form" class="flex flex-col" [formGroup]="form">
            <div class="field" *ngIf="form.controls.parent_zone">
                <label for="parent-zone">
                    {{ 'COMMON.EDGE' | translate }}
                </label>
                <item-search-field
                    [name]="'ZONE.SEARCH' | translate"
                    [query_fn]="query_fn"
                    [exclude]="exclude"
                    formControlName="parent_zone"
                ></item-search-field>
            </div>
            <div class="fieldset">
                <div class="field" *ngIf="form.controls.name">
                    <label
                        for="zone-name"
                        [class.error]="
                            form.controls.name.invalid &&
                            form.controls.name.touched
                        "
                    >
                        {{ 'COMMON.FIELD_NAME' | translate }}<span>*</span>
                    </label>
                    <mat-form-field appearance="outline">
                        <input
                            matInput
                            name="zone-name"
                            [placeholder]="'COMMON.FIELD_NAME' | translate"
                            formControlName="name"
                            required
                        />
                        <mat-error>{{
                            'ZONES.NAME_REQUIRED' | translate
                        }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="field" *ngIf="form.controls.display_name">
                    <label
                        for="zone-display"
                        [class.error]="
                            form.controls.display_name.invalid &&
                            form.controls.display_name.touched
                        "
                    >
                        {{ 'ZONES.DISPLAY_NAME' | translate }}
                    </label>
                    <mat-form-field appearance="outline">
                        <input
                            matInput
                            name="zone-display"
                            [placeholder]="'ZONES.DISPLAY_NAME' | translate"
                            formControlName="display_name"
                        />
                    </mat-form-field>
                </div>
            </div>
            <div class="field" *ngIf="form.controls.tags">
                <label
                    [class.error]="
                        form.controls.tags.invalid && form.controls.tags.touched
                    "
                >
                    {{ 'ZONES.TAGS' | translate }}
                </label>
                <mat-form-field appearance="outline" class="w-full">
                    <mat-chip-grid #chipList aria-label="Tag List">
                        <mat-chip-row
                            *ngFor="let item of tag_list"
                            (removed)="removeTag(item)"
                        >
                            <div class="max-w-md truncate">{{ item }}</div>
                            <button
                                matChipRemove
                                [attr.aria-label]="
                                    'COMMON.ITEM_REMOVE'
                                        | translate: { item: item }
                                "
                            >
                                <app-icon>cancel</app-icon>
                            </button>
                        </mat-chip-row>
                    </mat-chip-grid>
                    <input
                        [placeholder]="'ZONES.TAGS' | translate"
                        [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separators"
                        [matChipInputAddOnBlur]="true"
                        (matChipInputTokenEnd)="addTag($event)"
                    />
                </mat-form-field>
            </div>
            <div class="field" *ngIf="form.controls.description">
                <label for="description">
                    {{ 'COMMON.FIELD_DESCRIPTION' | translate }}
                </label>
                <mat-form-field appearance="outline">
                    <textarea
                        matInput
                        name="description"
                        [placeholder]="'COMMON.FIELD_DESCRIPTION' | translate"
                        formControlName="description"
                    ></textarea>
                </mat-form-field>
            </div>
            <div class="fieldset">
                <div class="field" *ngIf="form.controls.location">
                    <label for="location">{{
                        'ZONES.LOCATION' | translate
                    }}</label>
                    <mat-form-field appearance="outline">
                        <input
                            matInput
                            name="location"
                            [placeholder]="
                                'ZONES.LOCATION_PLACEHOLDER' | translate
                            "
                            formControlName="location"
                        />
                    </mat-form-field>
                </div>
                <div class="field">
                    <label for="timezone">
                        {{ 'COMMON.TIMEZONE' | translate }}
                    </label>
                    <mat-form-field appearance="outline">
                        <div class="prefix" matPrefix>
                            <app-icon class="relative -left-0.5 text-2xl">
                                search
                            </app-icon>
                        </div>
                        <input
                            matInput
                            formControlName="timezone"
                            [placeholder]="'COMMON.TIMEZONE' | translate"
                            [matAutocomplete]="auto"
                        />
                    </mat-form-field>
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option
                            *ngFor="let tz of filtered_timezones"
                            [value]="tz"
                            >{{ tz }}</mat-option
                        >
                        <mat-option *ngIf="!timezones.length" [disabled]="true">
                            {{ 'COMMON.TIMEZONE_EMPTY' | translate }}
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
            <div class="fieldset">
                <div class="field" *ngIf="form.controls.code">
                    <label for="code">{{ 'ZONES.CODE' | translate }}</label>
                    <mat-form-field appearance="outline">
                        <input
                            matInput
                            name="code"
                            [placeholder]="'ZONES.CODE_PLACEHOLDER' | translate"
                            formControlName="code"
                        />
                    </mat-form-field>
                </div>
                <div class="field" *ngIf="form.controls.location">
                    <label for="type">{{ 'ZONES.TYPE' | translate }}</label>
                    <mat-form-field appearance="outline">
                        <input
                            matInput
                            name="type"
                            [placeholder]="'ZONES.TYPE_PLACEHOLDER' | translate"
                            formControlName="type"
                        />
                    </mat-form-field>
                </div>
            </div>
            <div class="fieldset mb-4">
                <div class="field" *ngIf="form.controls.count">
                    <label for="count">{{ 'ZONES.COUNT' | translatec }}</label>
                    <a-counter
                        formControlName="count"
                        [min]="0"
                        [max]="999"
                    ></a-counter>
                </div>
                <div class="field" *ngIf="form.controls.capacity">
                    <label for="capacity">
                        {{ 'ZONES.CAPACITY' | translate }}
                    </label>
                    <a-counter
                        formControlName="capacity"
                        [min]="0"
                        [max]="999"
                    ></a-counter>
                </div>
            </div>
            <div class="field" *ngIf="form.controls.map_id">
                <label for="map">{{ 'ZONES.MAP_URL' | translate }}</label>
                <mat-form-field appearance="outline">
                    <input
                        matInput
                        name="map"
                        [placeholder]="'ZONES.MAP_URL' | translate"
                        formControlName="map_id"
                    />
                </mat-form-field>
            </div>
            <div class="field" *ngIf="form.controls.images">
                <label for="images">{{ 'COMMON.IMAGES' | translate }}</label>
                <image-list-field
                    name="images"
                    formControlName="images"
                ></image-list-field>
            </div>
        </form>
    `,
    styles: [``],
    standalone: false
})
export class ZoneFormComponent extends AsyncHandler {
    public timezones: string[] = [];
    public filtered_timezones: string[] = [];
    /** Group of form fields used for creating the system */
    @Input() public form: UntypedFormGroup;
    /** List of separator characters for tags */
    public readonly separators: number[] = [ENTER, COMMA, SPACE];
    /** Query function for zones */
    public readonly query_fn = (_: string) =>
        queryZones({ q: _ }).pipe(map((resp) => resp.data));
    /** Function to exclude zones */
    public readonly exclude = (zone: PlaceZone) =>
        zone.id === this.form.controls.id.value;

    public readonly addTag = (e) =>
        addChipItem(this.form.controls.tags as any, e);
    public readonly removeTag = (i) =>
        removeChipItem(this.form.controls.tags as any, i);

    public get tag_list(): string[] {
        return this.form.controls.tags.value;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.form) {
            this.updateTimezoneList();
            this.subscription(
                'tz-change',
                this.form.valueChanges.subscribe(
                    ({ timezone }) =>
                        (this.filtered_timezones = this.timezones.filter((_) =>
                            _.toLowerCase().includes(timezone.toLowerCase()),
                        )),
                ),
            );
            this.updateZone();
        }
    }

    public updateTimezoneList() {
        const timezone = this.form?.value?.timezone || '';
        this.timezones = TIMEZONES_IANA;
        this.filtered_timezones = this.timezones.filter((_) =>
            _.toLowerCase().includes(timezone.toLowerCase()),
        );
    }

    /** Update parent zone details if set */
    private async updateZone() {
        const parent_id = this.form.controls.parent_id
            ? this.form.controls.parent_id.value
            : '';
        if (parent_id) {
            const zone = await showZone(parent_id).toPromise();
            this.form.controls.parent_zone.setValue(zone);
        }
    }
}
