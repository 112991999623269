import { Component, Input, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AsyncHandler } from '../../common/async-handler.class';

import { Md5 } from 'ts-md5';

@Component({
    selector: 'application-form',
    template: `
        <form application *ngIf="form" class="flex flex-col" [formGroup]="form">
            <div class="fieldset">
                <div class="field" *ngIf="form.controls.name">
                    <label
                        for="application-name"
                        [class.error]="
                            form.controls.name.invalid &&
                            form.controls.name.touched
                        "
                    >
                        {{ 'COMMON.FIELD_NAME' | translate }}<span>*</span>:
                    </label>
                    <mat-form-field appearance="outline">
                        <input
                            matInput
                            name="application-name"
                            placeholder="Application Name"
                            formControlName="name"
                            required
                        />
                        <mat-error>{{
                            'DOMAINS.APP_NAME_REQUIRED' | translate
                        }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="field" *ngIf="form.controls.scopes">
                    <label for="scopes"
                        >{{ 'DOMAINS.APP_SCOPES' | translate }}:</label
                    >
                    <mat-form-field appearance="outline">
                        <input
                            matInput
                            name="scopes"
                            [placeholder]="'DOMAINS.APP_SCOPES' | translate"
                            formControlName="scopes"
                        />
                    </mat-form-field>
                </div>
            </div>
            <div class="field" *ngIf="form.controls.redirect_uri">
                <label for="redirect-uri"
                    >{{ 'DOMAINS.APP_REDIRECT_URL' | translate }}:
                </label>
                <mat-form-field appearance="outline">
                    <input
                        matInput
                        name="redirect-uri"
                        placeholder="Redirect URI e.g. http://localhost:4200/oauth-resp.html"
                        formControlName="redirect_uri"
                    />
                    <mat-error>{{
                        'DOMAINS.APP_REDIRECT_URL_REQUIRED' | translate
                    }}</mat-error>
                </mat-form-field>
            </div>
            <div class="fieldset mb-4">
                <settings-toggle
                    class="flex-1"
                    [name]="'DOMAINS.APP_SKIP' | translate"
                    formControlName="skip_authorization"
                ></settings-toggle>
                <settings-toggle
                    class="flex-1"
                    [name]="'DOMAINS.APP_PRESERVE_ID' | translate"
                    formControlName="preserve_client_id"
                ></settings-toggle>
            </div>
            <div class="field" *ngIf="form.controls.redirect_uri">
                <label for="client-id"
                    >{{ 'DOMAINS.APP_CLIENT_ID' | translate }}:
                </label>
                <mat-form-field appearance="outline">
                    <input
                        matInput
                        name="client-id"
                        [placeholder]="
                            'DOMAINS.APP_CLIENT_PLACEHOLDER' | translate
                        "
                        [disabled]="true"
                        [ngModel]="client_id | async"
                        [ngModelOptions]="{ standalone: true }"
                    />
                </mat-form-field>
            </div>
        </form>
    `,
    styles: [
        `
            settings-form-field {
                margin-bottom: 1.5em;
            }
        `,
    ],
    standalone: false
})
export class ApplicationFormComponent extends AsyncHandler {
    /** Group of form fields used for creating the system */
    @Input() public form: UntypedFormGroup;

    public default_redirect_uri: string;

    public readonly client_id = new BehaviorSubject('');

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.form && this.form) {
            const { id, client_id, redirect_uri } = this.form.value;
            this.default_redirect_uri = redirect_uri || '';
            this.client_id.next(
                client_id || redirect_uri
                    ? Md5.hashStr(redirect_uri || '')
                    : '',
            );
            this.subscription(
                'form.redirect_uri',
                this.form
                    .get('redirect_uri')
                    .valueChanges.subscribe((value: string) => {
                        if (this.form.value.preserve_client_id) return;
                        this.client_id.next(value ? Md5.hashStr(value) : '');
                        this.form.patchValue(
                            { redirect_uri: value?.trim() },
                            { emitEvent: false },
                        );
                    }),
            );
            this.subscription(
                'form.preserve_client_id',
                this.form
                    .get('preserve_client_id')
                    .valueChanges.subscribe((preserve: boolean) => {
                        const value = this.form.value.redirect_uri;
                        const uri = preserve
                            ? this.default_redirect_uri
                            : value;
                        this.client_id.next(uri ? Md5.hashStr(uri) : '');
                    }),
            );
        }
    }
}
