{
    "COMMON": {
        "SYSTEMS": "Systems",
        "MODULES": "Modules",
        "ZONES": "Zones",
        "DRIVERS": "Drivers",
        "REPOS": "Repositories",
        "TRIGGERS": "Triggers",
        "METRICS": "Metrics",
        "USERS": "Users",
        "DOMAINS": "Domains",
        "EDGE": "Edge",
        "EDGE_SEARCH": "Search for edges...",
        "MANAGE": "Manage Instance",
        "ADMIN": "Admin",
        "USER_ADMIN": "Admin",
        "USER_SUPPORT": "Support",
        "USER_BASIC": "Basic",
        "DEBUG_ENABLED": "Debugging Enabled",
        "DEBUG_LISTENING_MSG": "Listening to {{ modules }} module(s)",
        "DEBUG_MSG_COUNT_MSG": "{{ count }} module messages",
        "DEBUG_NO_MESSAGES": "No debug messages to display",
        "PROFILE": "Profile",
        "DARK_MODE": "Dark Mode",
        "LOGOUT": "Logout",
        "UPLOAD_HISTORY": "Upload History",
        "LANGUAGE": "Language",
        "REPORT_ISSUE": "Report an Issue",
        "UPLOAD_FILE": "Upload File",
        "FILENAME": "Filename",
        "PUBLIC": "Public",
        "PERMISSIONS": "Permissions",
        "NOTES": "Notes",
        "NONE": "None",
        "CANCEL": "Cancel",
        "CONFIRM": "Confirm",
        "SAVE": "Save",
        "SAVE_ALL": "Save All",
        "CLEAR": "Clear Changes",
        "SAVE_CHANGES": "Save Changes",
        "SAVE_ITEMS": "Save items",
        "BACK": "Back",
        "NEXT": "Next",
        "CONTINUE": "Continue",
        "UPDATE": "Update",
        "UPLOAD": "Upload",
        "UPLOADS": "Uploads",
        "LINK": "Link",
        "RETRY": "Retry",
        "IMAGES": "Images",
        "NEW_ITEM": "New item",
        "ITEM_ADD": "Add {{ item }}",
        "ITEM_ADD_SELECTED": "Add selected",
        "PROCESSING_REQUEST": "Processing request...",
        "PASSWORD": "Password",
        "IMAGE_ADD_URL": "Add image via URL",
        "IMAGE_UPLOADS": "Upload Image(s)",
        "DROP_UPLOAD_MSG": "Drop files to upload them to the cloud",
        "NO_UPLOADS": "No uploads to show",
        "CLEAR_UPLOADS": "Clear completed uploads",
        "FORBIDDEN": "Access forbidden.",
        "INVALID_PAGE_PERMISSIONS": "You do not have permission to view this page and your access attempt has been recorded.",
        "CONTACT_ADMIN": "Contact your Administrator if you feel that you should have access.",
        "SELECTED_FIRST_VERSION": "Selected setting is the first version",
        "SETTINGS_COMPARE_SELECT_MSG": "Select an current setting and old setting to compare them.",
        "SELECT_OLD_SETTING": "Select old settings",
        "SELECT_NEW_SETTING": "Select current settings",
        "METADATA_NEW": "New Metadata block",
        "METADATA_EDIT": "Edit Metadata details",
        "METADATA_REMOVE": "Remove Metadata block",
        "METADATA_EMPTY": "No Metadata blocks found",
        "METADATA_SAVE": "Successfully saved metadata",
        "METADATA_HISTORY": "Metadata History",
        "METADATA_OWNER": "Metadata Owner",
        "METADATA_KEY": "Metadata Key",
        "TOTAL_ITEMS": "{{ count }} items(s)",
        "ITEM_ERROR": "Failed to save item. Error: {{ error }}",
        "ITEM_SAVE": "Successfully saved item",
        "UPDATE_AVAILABLE": "Update Available",
        "END_OF_LIST": "End of the list",
        "SEARCH_EMPTY": "No matching {{ name }} found",
        "LIST_EMPTY": "No {{ name }} available",
        "SEARCH": "Search...",
        "SEARCH_FOR": "Search for {{ name }}...",
        "VIEW_TYPE": "View {{ name }}",
        "ONLINE": "Online",
        "OFFLINE": "Offline",
        "SECURE": "Secure",
        "TLS": "TLS",
        "UDP": "UDP",
        "EDIT_TYPE": "Edit {{ name }}",
        "CREATE_FROM_TYPE": "Create new from this {{ name }}",
        "DUPLICATE_TYPE": "Duplicate {{ name }}",
        "EXPORT_TYPE_AS_TSV": "Export {{ name }} as TSV Template",
        "DELETE_TYPE": "Delete {{ name }}",
        "COPIED_ID": "ID copied to clipboard",
        "MESSAGE_COUNT": "{{ count }} message(s)",
        "DEBUG_DOWNLOAD_MESSAGES": "Download messages",
        "DEBUG_TOGGLE_POSITION": "Toggle Position",
        "DEBUG_CLEAR_MESSAGES": "Download messages",
        "DEBUG_UNBIND_MODULES": "Unbind Modules",
        "DEBUG_CLOSE_CONSOLE": "Close Console",
        "TRUE": "Yes",
        "FALSE": "No",
        "TIMEZONE": "Timezone",
        "TIMEZONE_EMPTY": "No matching timezones",
        "CREATED_AT": "Created",
        "UPDATED_AT": "Updated",
        "SETTINGS": "Settings",
        "LAST_EDIT": "Last edited",
        "SETTINGS_PLAINTEXT": "Unencrypted",
        "SETTINGS_SUPPORT": "Support",
        "SETTINGS_ADMIN": "Admin",
        "SETTINGS_ENCRYPTED": "Encrypted",
        "SETTINGS_MERGED": "Merged",
        "SETTINGS_MASKED": "MASKED",
        "SETTINGS_INHERITED": "Setting inherited from [{{ parent }}]({{ path }})({{ type }})",
        "SETTINGS_LOCAL": "Local setting from {{ type }}",
        "SETTINGS_SAVE_ERROR": "Failed to save settings. Error {{ error }}",
        "SETTINGS_SAVE_SUCCESS": "Successfully saved {{ type }} settings changes",
        "SETTINGS_SAVE_SUCCESS_ALL": "Successfully saved all settings changes",
        "ADD_EXISTING": "Add existing",
        "ADD_NEW": "Add new",
        "EXECUTE_COMMAND": "Execute command",
        "EXECUTE_CLEAR": "Clear selection",
        "EXECUTE_PERFORM": "Execute",
        "EXECUTE_LOADING": "Executing method...",
        "EXECUTE_MODULE_SELECT": "Select module",
        "EXECUTE_MODULE_LOADING": "Loading module list...",
        "EXECUTE_REQUIRED": "Required",
        "EXECUTE_OPTIONAL": "Optional",
        "EXECUTE_NO_ARGS": "No arguments for selected method",
        "EXECUTE_METHOD_SELECT": "Select method",
        "EXECUTE_METHOD_LOADING": "Loading method list...",
        "EXECUTE_METHOD_EMPTY": "No available methods for module",
        "BLANK_NAME": "BLANK",
        "FIELD_NAME": "Name",
        "FIELD_DESCRIPTION": "Description",
        "FIELD_EMAIL": "Email",
        "VERSION": "Version",
        "VERSION_CURRENT": "Current Version",
        "VERSION_LATEST": "Latest Version",
        "GIT_BRANCH": "Branch",
        "GIT_COMMIT": "Commit hash",
        "GIT_PULL_LATEST": "Pull latest commit",
        "DESCRIPTION_EMPTY": "No description",
        "INVALID_FIELDS": "Some fields are invalid. [{{ field_list }}]",
        "BULK_ADD": "Bulk add {{ type }}",
        "BULK_DOWNLOAD": "Download Template",
        "BULK_DROP_MSG": "Select or drop TSV file to begin",
        "BULK_DROP_LOADING": "Processing TSV data...",
        "BULK_UPLOADING": "Uploading items...",
        "LANG_ENGLISH": "English",
        "LANG_JAPANESE": "Japanese",
        "LANG_FRENCH": "French",
        "LANG_SPANISH": "Spanish",
        "LANG_ARABIC": "Arabic",
        "CHANGELOG": "Changelog",
        "CHANGELOG_EMPTY": "No Changelog",
        "VIEW_RESPONSE": "Exec Request Results",
        "MINUTE": "Minute",
        "HOUR": "Hour",
        "DAY": "Day",
        "WEEK": "Week",
        "MONTH": "Month",
        "YEAR": "Year",
        "SUNDAY": "Sunday",
        "MONDAY": "Monday",
        "TUESDAY": "Tuesday",
        "WEDNESDAY": "Wednesday",
        "THURSDAY": "Thursday",
        "FRIDAY": "Friday",
        "SATURDAY": "Saturday",
        "JANUARY": "January",
        "FEBRUARY": "February",
        "MARCH": "March",
        "APRIL": "April",
        "MAY": "May",
        "JUNE": "June",
        "JULY": "July",
        "AUGUST": "August",
        "SEPTEMBER": "September",
        "OCTOBER": "October",
        "NOVEMBER": "November",
        "DECEMBER": "December",
        "DATE_JUST_NOW": "Just now",
        "DATE_FROM_IN_LESS_MINUTE": "In less than a minute",
        "DATE_FROM_LESS_MINUTE_AGO": "Less than a minute ago",
        "DATE_FROM_IN_ABOUT_MINUTE": "In about 1 minute",
        "DATE_FROM_ABOUT_MINUTE_AGO": "About a minute ago",
        "DATE_FROM_IN_MINUTES": "In {{ minutes }} minutes",
        "DATE_FROM_MINUTES_AGO": "{{ minutes }} minutes ago",
        "DATE_FROM_IN_HOURS": "In {{ hours }} hour(s)",
        "DATE_FROM_HOURS_AGO": "{{ hours }} hour(s) ago",
        "DATE_FROM_IN_DAYS": "In {{ days }} days(s)",
        "DATE_FROM_DAYS_AGO": "{{ days }} days(s) ago",
        "DATE_FROM_IN_MONTHS": "In {{ months }} month(s)",
        "DATE_FROM_MONTHS_AGO": "{{ months }} month(s) ago",
        "DATE_FROM_IN_YEAS": "In {{ years }} year(s)",
        "DATE_FROM_YEARS_AGO": "{{ years }} year(s) ago",
        "CRON_CUSTOM": "Custom"
    },
    "SYSTEMS": {
        "SINGULAR": "System",
        "PLURAL": "Systems",
        "SEARCH": "Search for systems...",
        "ADD": "Add system",
        "EDIT": "Edit system",
        "DELETE": "Delete system",
        "DELETE_MSG": "<p>Are you sure you want delete this system(<strong>{{ name }}</strong>)?</p><p>Deleting this will <strong>immediately</strong> delete modules that are not in another system</p>",
        "DELETE_LOADING": "Deleting system...",
        "DELETE_SUCCESS": "Successfully deleted system.",
        "DELETE_ERROR": "Failed to delete system. Error: {{ error }}",
        "SAVING": "Saving system...",
        "SAVE_ERROR": "Failed to save system. Error: {{ error }}",
        "SAVE_SUCCESS": "Successfully saved system.",
        "REMOVE": "Remove system",
        "NEW": "New system",
        "BULK": "Bulk add systems",
        "SUPPORT_URL": "Support URL",
        "BOOKABLE": "Bookable Room",
        "FEATURES": "Features",
        "SIGNAGE": "Signage",
        "PUBLIC": "Public",
        "CODE": "Code",
        "EMAIL": "Email",
        "CAPACITY": "Capacity",
        "MAP_ID": "Map ID",
        "PANEL_COUNT": "Installed Touch Panels",
        "CONTROLS": "System Controls",
        "START": "START",
        "STOP": "STOP",
        "REMOVE_ITEM": "Remove {{ item }}",
        "LOADING_SETTINGS": "Loading system settings...",
        "TAB_ABOUT": "About",
        "TAB_MODULES": "Modules",
        "TAB_ZONES": "Zones",
        "TAB_TRIGGERS": "Triggers",
        "TAB_METADATA": "Metadata",
        "TAB_SETTINGS_HISTORY": "Settings History",
        "FIND_MODULE": "Find existing module...",
        "MODULE_LIST": "Module List",
        "MODULE_FIELD_STATE": "State",
        "MODULE_FIELD_NAME": "Name",
        "MODULE_FIELD_TYPE": "Type",
        "MODULE_FIELD_CLASS": "Class",
        "MODULE_FIELD_ADDRESS": "Address",
        "MODULE_FIELD_DEBUG": "Debug",
        "MODULE_LIST_EMPTY": "No modules for the selected system",
        "DEBUG_ENABLE": "Enable debugging",
        "DEBUG_DISABLE": "Disable debugging",
        "VIEW_ORIGINAL": "View Original",
        "ZONE_FIELD_NAME": "Name",
        "ZONE_FIELD_DESCRIPTION": "Description",
        "ZONE_DESCRIPTION_EMPTY": "No description",
        "ZONE_REMOVE": "Remove system from zone",
        "ZONE_SEARCH": "Search for zones...",
        "TRIGGER_EDIT": "Edit Trigger",
        "TRIGGER_REMOVE": "Remove Trigger",
        "COPY_WEBHOOK": "Copy webhook",
        "COPIED_WEBHOOK": "Webhook link copied to clipboard",
        "TRIGGER_FIELD_NAME": "Name",
        "TRIGGER_FIELD_COUNT": "Count",
        "TRIGGER_FIELD_ERRORS": "Errors",
        "TRIGGER_FIELD_ADDED": "Added",
        "TRIGGERS_EMPTY": "No triggers for selected system",
        "TRIGGER_ADD": "Add trigger",
        "TRIGGER_SEARCH": "Search triggers...",
        "ZONE_REQUIRED": "A zone is required",
        "NAME_REQUIRED": "A unique system name is required",
        "EMAIL_REQUIRED": "A valid email is required",
        "DISPLAY_NAME": "Display name",
        "URL_VALID": "A valid URL is required",
        "TRIGGER_NAME": "Trigger Name",
        "TRIGGER_ACTIVE": "Trigger Active",
        "TRIGGER_ENABLED": "Enabled",
        "TRIGGER_EXECUTE_ENABLED": "Execute Enabled",
        "TRIGGER_IMPORTANT": "Important",
        "MISCONFIGURED": "Room system does not contain all required zones"
    },
    "MODULES": {
        "SINGULAR": "Module",
        "PLURAL": "Modules",
        "SEARCH": "Search for modules...",
        "ADD": "Add module",
        "DELETE": "Delete module",
        "DELETE_MSG": "<p>Are you sure you want delete this module?</p><p>Deleting this will module <strong>immediately</strong> remove it from any system associated with it</p>",
        "DELETE_LOADING": "Deleting module...",
        "DELETE_SUCCESS": "Successfully deleted module.",
        "DELETE_ERROR": "Failed to delete module. Error: {{ error }}",
        "SAVING": "Saving module...",
        "SAVE_ERROR": "Failed to save module. Error: {{ error }}",
        "SAVE_SUCCESS": "Successfully saved module.",
        "REMOVE": "Remove module",
        "RUNTIME_ERRORS_VIEW": "View Runtime Errors",
        "RUNTIME_ERRORS_NO": "No runtime errors found",
        "RUNTIME_ERRORS_LOADING": "Loading runtime errors details...",
        "TOGGLE_POWER": "Toggle Power",
        "VIEW_STATE": "View State",
        "EDIT": "Edit Module",
        "LOAD": "Load Module",
        "RELOAD": "Reload Module",
        "VIEW_ERRORS": "View runtime errors",
        "ADD_TO_SYSTEM": "Add to another system",
        "NOTES": "Notes",
        "IP_ADDRESS": "IP",
        "PORT_NUMBER": "Port Number",
        "PROTOCOL": "Protocol",
        "CONTROLS": "Module Controls",
        "START": "START",
        "STOP": "STOP",
        "LOADING_SETTINGS": "Loading module settings...",
        "TAB_ABOUT": "About",
        "TAB_SYSTEMS": "Systems",
        "TAB_SETTINGS_HISTORY": "Settings History",
        "NEW": "New module",
        "SYSTEMS_FIELD_NAME": "Name",
        "SYSTEMS_FIELD_MODULE_COUNT": "No. Modules",
        "SYSTEMS_EMPTY": "No systems with the selected module",
        "FIELD_STATE": "State",
        "DRIVER_REQUIRED": "A driver is required",
        "CONTROL_SYSTEM": "Control System",
        "SYSTEM_REQUIRED": "A control system is required",
        "URI": "Module URI",
        "URI_REQUIRED": "A valid URI is required",
        "FIELD_IP": "IP Address of FQDN",
        "IP_REQUIRED": "A valid IP address or domain name is required",
        "PORT_REQUIRED": "A valid port number from 1 to 65535 is required",
        "MAKEBREAK": "Makebreak",
        "IGNORE_CONNECTED": "Ignore connected",
        "CUSTOM_NAME": "Custom Name",
        "STATE": "Module State",
        "STATE_UPDATE": "Update state",
        "STATE_LOADING": "Loading module's state...",
        "ERROR": "Runtime error in the module"
    },
    "ZONES": {
        "SINGULAR": "Zone",
        "PLURAL": "Zones",
        "SEARCH": "Search for zones...",
        "ADD": "Add zone",
        "EDIT": "Edit zone",
        "DELETE": "Delete zone",
        "DELETE_MSG": "<p>Are you sure you want delete this zone?</p><p>Deleting this zone will <strong>immediately</strong> remove systems without another zone</p>",
        "DELETE_LOADING": "Deleting zone...",
        "DELETE_SUCCESS": "Successfully deleted zone.",
        "DELETE_ERROR": "Failed to delete zone. Error: {{ error }}",
        "SAVING": "Saving zone...",
        "SAVE_ERROR": "Failed to save zone. Error: {{ error }}",
        "SAVE_SUCCESS": "Successfully saved zone.",
        "REMOVE": "Remove zone",
        "NEW": "New zone",
        "BULK": "Bulk add zones",
        "TAB_ABOUT": "About",
        "TAB_SYSTEMS": "Systems",
        "TAB_TRIGGERS": "Triggers",
        "TAB_METADATA": "Metadata",
        "TAB_CHILDREN": "Children",
        "TAB_SETTINGS_HISTORY": "Settings History",
        "TAG_WARNING": "Tags set in this zone require a parent zone to work correctly.",
        "SELECT_SYSTEM": "Select system",
        "PARENT_ID": "Parent ID",
        "LOCATION": "Location",
        "LOCATION_PLACEHOLDER": "Geo-location details for zone. <lat, long>",
        "CODE": "Code",
        "CODE_PLACEHOLDER": "Organisation Code",
        "TYPE": "Type",
        "TYPE_PLACEHOLDER": "Organisation Category",
        "COUNT": "Count",
        "CAPACITY": "Capacity",
        "MAP_URL": "Map URL",
        "TAGS": "Tags",
        "TAGS_EMPTY": "No tags for zone",
        "DESCRIPTION": "Description",
        "LOADING_SETTINGS": "Loading zone settings...",
        "SYSTEMS_FIELD_MODULE_COUNT": "No. Modules",
        "SYSTEMS_EMPTY": "No systems with the selected zone",
        "CHILDREN_EMPTY": "No child zones with the selected zone",
        "DESCRIPTION_EMPTY": "No description",
        "TRIGGERS_EMPTY": "No triggers for selected zone",
        "PARENT_ZONE": "Parent Zone",
        "NAME_REQUIRED": "A unique zone name is required",
        "DISPLAY_NAME": "Display name",
        "MISCONFIGURED": "Tags in zone require a parent zone"
    },
    "DRIVERS": {
        "SINGULAR": "Driver",
        "PLURAL": "Drivers",
        "DEVICE": "Device",
        "SSH": "SSH",
        "SERVICE": "Service",
        "WEBSOCKET": "Websocket",
        "LOGIC": "Logic",
        "UNKNOWN": "Unknown",
        "SEARCH": "Search for drivers...",
        "ADD": "Add driver",
        "EDIT": "Edit driver",
        "DELETE": "Delete driver",
        "DELETE_MSG": "<p>Are you sure you want delete this driver?</p>",
        "DELETE_LOADING": "Deleting driver...",
        "DELETE_SUCCESS": "Successfully deleted driver.",
        "DELETE_ERROR": "Failed to delete driver. Error: {{ error }}",
        "SAVING": "Saving driver...",
        "SAVE_ERROR": "Failed to save driver. Error: {{ error }}",
        "SAVE_SUCCESS": "Successfully saved driver.",
        "REMOVE": "Remove driver",
        "NEW": "New driver",
        "LOADING": "Loading drivers...",
        "UPDATE": "Update Drivers",
        "UPDATE_COUNT": "{{ count }} updates available",
        "UPDATE_SELECTED": "Update selected drivers",
        "NO_UPDATES": "No drivers require updating.",
        "TAB_ABOUT": "About",
        "TAB_MODULES": "Modules",
        "TAB_SETTINGS_HISTORY": "Settings History",
        "DEFAULT_URI": "Default URI",
        "DEFAULT_PORT": "Default Port",
        "MODULE_NAME": "Module Name",
        "MODULE_NAME_REQUIRED": "A module name is required",
        "COMPILED": "Compiled",
        "VIEW_ERRORS": "View errors",
        "FILENAME": "Filename",
        "RECOMPILE": "Recompile",
        "RELOAD": "Reload",
        "LOADING_SETTINGS": "Loading driver settings...",
        "MODULES_EMPTY": "No modules created for driver",
        "VIEW_SYSTEMS": "View module's systems",
        "SYSTEM_COUNT": "{{ count }} system(s)",
        "LOADING_SYSTEMS": "Loading module systems...",
        "DRIVER_LIST_ERROR": "Failed to load driver list. Error: {{ error }}",
        "COMMIT_LIST_ERROR": "Failed to load driver's commit list. Error: {{ error }}",
        "BASE": "Base Driver",
        "COMMIT": "Driver Commit",
        "COMMIT_SEARCH": "Search for commit...",
        "NAME_REQUIRED": "A driver name is required",
        "ROLE": "Role",
        "COMMITS_LOADING": "Loading commits for the selected driver...",
        "DRIVERS_LOADING": "Loading driver list from selected repository...",
        "DETAILS_LOADING": "Loading driver details for selected commit...",
        "DETAILS_ERROR_1": "Failed to load driver details for commit.",
        "DETAILS_ERROR_2": "Please check that the driver compiles correctly and try again."
    },
    "REPOS": {
        "SINGULAR": "Repository",
        "PLURAL": "Repositories",
        "SEARCH": "Search for repositories...",
        "ADD": "Add repository",
        "EDIT": "Edit repository",
        "DELETE": "Delete repository",
        "REMOVE": "Remove repository",
        "NEW": "New repository",
        "DELETE_MSG": "<p>Deleting this repository will <strong>immediately</strong> remove assoicated drivers and modules</p>",
        "DELETE_LOADING": "Deleting repository...",
        "DELETE_SUCCESS": "Successfully deleted repository.",
        "DELETE_ERROR": "Failed to delete repository. Error: {{ error }}",
        "SAVING": "Saving repository...",
        "SAVE_ERROR": "Failed to save repository. Error: {{ error }}",
        "SAVE_SUCCESS": "Successfully saved repository.",
        "LOADING": "Loading repositories...",
        "TAB_ABOUT": "About",
        "TAB_DRIVERS": "Drivers",
        "INTERFACE_REPO": "Interface Repository",
        "DRIVER_REPO": "Driver Repository",
        "TYPE_INTERFACE": "Interface",
        "TYPE_DRIVER": "Driver",
        "FIELD_TYPE": "Type",
        "TYPE": "Repository Type",
        "FOLDER_NAME": "Folder name",
        "FOLDER_NAME_EMPTY": "No folder set",
        "FOLDER_NAME_REQUIRED": "A folder name is required",
        "URI": "Repository URI",
        "AVAILABLE_DRIVERS": "Available Drivers",
        "DRIVER_LIST_EMPTY": "No available drivers in repository",
        "GIT_PULL_ERROR": "Error pulling latest commit. Error: {{ error }}",
        "GIT_PULL_TIMEOUT": "Pull timed out",
        "USERNAME": "Repository Username",
        "PASSWORD": "Repository Password",
        "URI_REQUIRED": "A URI is required",
        "BRANCH": "Branch",
        "COMMIT": "Repository Commit",
        "NAME_REQUIRED": "A repository name is required",
        "BRANCH_REQUIRED": "A working branch is required",
        "COMMIT_REQUIRED": "A commit is required"
    },
    "TRIGGERS": {
        "SINGULAR": "Trigger",
        "PLURAL": "Triggers",
        "SEARCH": "Search for triggers...",
        "ADD": "Add trigger",
        "EDIT": "Edit trigger",
        "NEW": "New trigger",
        "DELETE": "Delete trigger",
        "DELETE_INSTANCE": "Delete instance",
        "REMOVE": "Remove trigger",
        "DELETE_MSG": "<p>Are you sure you want delete this trigger?</p><p>Deleting this trigger will <strong>immediately</strong> remove it from all associated systems and zones</p>",
        "DELETE_LOADING": "Deleting trigger...",
        "DELETE_SUCCESS": "Successfully deleted trigger.",
        "DELETE_ERROR": "Failed to delete trigger. Error: {{ error }}",
        "SAVING": "Saving trigger...",
        "SAVE_ERROR": "Failed to save trigger. Error: {{ error }}",
        "SAVE_SUCCESS": "Successfully saved trigger.",
        "LOADING": "Loading triggers...",
        "TAB_ABOUT": "About",
        "TAB_INSTANCES": "Instances",
        "FIELD_ADDED": "Added",
        "FIELD_STATE": "State",
        "FIELD_INSTANCE_NAME": "Instance Name",
        "INSTANCES_EMPTY": "No instance of trigger",
        "REFERENCE_SYSTEM": "Reference System",
        "REFERENCE_SYSTEM_MSG": "System to use for available status variables and function calls",
        "CONDITIONS": "Conditions",
        "CONDITION_ADD": "Add Condition",
        "CONDITION_NEW": "New Condition",
        "CONDITION_EDIT": "Edit Condition",
        "CONDITION_FIELD_TYPE": "Condition Type",
        "CONDITION_COMPARE": "Compare Values",
        "CONDITION_TIME": "Particular Time",
        "CONDITION_COMPARE_EMPTY": "No variable comparision conditions for this trigger",
        "CONDITION_TIME_EMPTY": "No time dependant conditions for this trigger",
        "CONDITION_SAVE_ERROR": "Failed to save trigger condition. Error {{ error }}",
        "CONDITION_SAVE_SUCCESS": "Successfully saved trigger condtion",
        "FIELD_VAR_COMPARE": "Variable Comparison Condition",
        "FIELD_TIME_DEPS": "Time dependant Condition",
        "ACTIONS": "Actions",
        "ACTION_ADD": "Add Action",
        "ACTION_NEW": "New Action",
        "ACTION_EDIT": "Edit Action",
        "ACTION_FIELD_TYPE": "ACtion Type",
        "ACTION_EXECUTE": "Execute Method",
        "ACTION_EXECUTE_SELECT": "Select method to execute",
        "ACTION_EMAIL": "Send Email",
        "ACTION_EMAIL_BODY": "Email Body",
        "ACTION_EMAIL_ADDRESS": "Email addresses",
        "ACTION_EMAIL_ADDRESS_LIST": "List of email addresses",
        "ACTION_EMAIL_EMPTY": "No email actions for this trigger",
        "ACTION_FN_EMPTY": "No function call actions for this trigger",
        "FIELD_ACTION_FN_CALL": "Function call Action",
        "FIELD_ACTION_EMAIL": "Email Action",
        "REORDER_CONFIRM_TITLE": "Reoreder trigger {{ type }} action",
        "REORDER_CONFIRM_MSG": "Are you sure you want remove this trigger condition?<br>All systems using this trigger will be updated <strong>immediately</strong>.",
        "REORDER_CONFIRM_LOADING": "Re-ordering trigger actions...",
        "REORDER_CONFIRM_ERROR": "Failed to re-ordered trigger action. Error: {{ error }}",
        "REORDER_CONFIRM_SUCCESS": "Successfully re-ordered trigger action",
        "REMOVE_CONDITION_TITLE": "Remove trigger condition",
        "REMOVE_CONDITION_MSG": "Are you sure you want remove this trigger condition?<br>All systems using this trigger will be updated <strong>immediately</strong>.",
        "REMOVE_CONDITION_LOADING": "Removing trigger condition...",
        "REMOVE_CONDITION_ERROR": "Failed to remove trigger condition. Error: {{ error }}",
        "REMOVE_CONDITION_SUCCESS": "Successfully removed trigger condition.",
        "REMOVE_ACTION_TITLE": "Remove trigger action",
        "REMOVE_ACTION_MSG": "Are you sure you want remove this trigger action?<br>All systems using this trigger will be updated <strong>immediately</strong>.",
        "REMOVE_ACTION_LOADING": "Removing trigger action...",
        "REMOVE_ACTION_ERROR": "Failed to remove trigger action. Error: {{ error }}",
        "REMOVE_ACTION_SUCCESS": "Successfully removed trigger action.",
        "REMOVE_INSTANCE_TITLE": "Remove trigger from {{ type }}",
        "REMOVE_INSTANCE_MSG": "Are you sure you want remove this trigger from {{ name }}?<br>The {{ type }} will be updated <strong>immediately</strong>.",
        "REMOVE_INSTANCE_LOADING": "Removing trigger from {{ type }}",
        "REMOVE_INSTANCE_ERROR": "Failed to remove trigger from {{ type }}. Error: {{ error }}",
        "REMOVE_INSTANCE_SUCCESS": "Successfully remove trigger from {{ type }}",
        "TIME_SCHEDULE": "Recurring Schedule",
        "TIME_REPEAT": "Repeat Every",
        "TIME_DAY_OF_MONTH": "Day of Month",
        "TIME_MONTH_OF_YEAR": "Month of Year",
        "TIME_DAY_OF_WEEK": "Day of Week",
        "TIME_HOUR_OF_DAY": "Hour of the day",
        "TIME_MINUTE_OF_HOUR": "Minute of the hour",
        "TIME_FIELD_DATE": "Date",
        "TIME_FIELD_TIME": "Time",
        "COMPARE_CONSTANT": "Constant Value",
        "COMPARE_VARIABLE": "Status Variable",
        "COMPARE_VARIABLE_SELECT": "Select status variable",
        "COMPARE_VARIABLE_ERROR": "A module status variable is required",
        "COMPARE_OP": "Operation",
        "COMPARE_OP_EQUAL": "equal to",
        "COMPARE_OP_NOT_EQUAL": "not equal to",
        "COMPARE_OP_GREATER": "greater than",
        "COMPARE_OP_GREATER_EQUAL": "greater than or equal",
        "COMPARE_OP_LESS": "less than",
        "COMPARE_OP_LESS_EQUAL": "less than or equal",
        "COMPARE_OP_AND": "truthy AND",
        "COMPARE_OP_OR": "truthy OR",
        "COMPARE_OP_XOR": "truthy XOR",
        "COMPARE_OP_SELECT": "Select comparision operator",
        "COMPARE_TO": "Compared to",
        "COMPARE_JSON_REQUIRED": "Valid JSON is required",
        "COMPARE_MODULE_SELECT": "Select module",
        "COMPARE_SUBKEYS": "Subkeys",
        "COMPARE_SUBKEYS_PLACEHOLDER": "Status variable subkeys",
        "COMPARE_VARIABLE_LOAD_ERROR": "Failed to load status variables from {{ system }}, {{ module }}",
        "ENABLE_WEBHOOK": "Enable Webhook",
        "DEBOUNCE_PERIOD": "Debounce Period",
        "SUPPORTED_METHODS": "Supported Methods"
    },
    "USERS": {
        "SINGULAR": "User",
        "PLURAL": "Users",
        "SEARCH": "Search for users...",
        "ADD": "Add user",
        "EDIT": "Edit user",
        "NEW": "New user",
        "BULK": "Bulk add users",
        "REMOVE": "Remove user",
        "DELETE_MSG": "<p>Are you sure you want delete this user?</p><p>The user will be removed from the system within 24 hours</p>",
        "DELETE_LOADING": "Deleting user...",
        "DELETE_SUCCESS": "Successfully deleted user.",
        "DELETE_ERROR": "Failed to delete user. Error: {{ error }}",
        "SAVING": "Saving user...",
        "SAVE_ERROR": "Failed to save user. Error: {{ error }}",
        "SAVE_SUCCESS": "Successfully saved user.",
        "LOADING": "Loading users...",
        "TAB_ABOUT": "About",
        "TAB_METADATA": "Metadata",
        "TAB_HISTORY": "History",
        "ROLE_SUPPORT": "PlaceOS Support",
        "ROLE_ADMIN": "System Admin",
        "ROLE_NONE": "None",
        "FIELD_GROUPS": "User Groups",
        "FIELD_DEPARTMENT": "Department",
        "AUTHORITY_ID": "Authority ID",
        "NO_GROUPS": "No user groups",
        "FIELD_SESSION_START": "Session Start",
        "FIELD_SESSION_END": "End",
        "FIELD_SYSTEMS_ACCESSED": "Systems Accessed",
        "VIEW_LOGS": "View",
        "LOGS_EMPTY": "No logs found for this user",
        "FIRST_NAME": "First Name",
        "FIRST_NAME_REQUIRED": "A first name is required",
        "LAST_NAME": "Last Name",
        "LAST_NAME_REQUIRED": "A last name is required",
        "EMAIL_REQUIRED": "A valid email is required",
        "STAFF_ID": "Staff ID",
        "STAFF_CARD": "Card Number",
        "PASSWORD_REQUIRED": "A valid password is required",
        "PASSWORD_CONFIRM": "Confirm Password",
        "PASSOWRD_MATCH": "Passwords do not match"
    },
    "DOMAINS": {
        "SINGULAR": "Domain",
        "PLURAL": "Domains",
        "SEARCH": "Search for domain...",
        "ADD": "Add domain",
        "EDIT": "Edit domain",
        "NEW": "New domain",
        "BULK": "Bulk add domains",
        "REMOVE": "Remove domain",
        "DELETE": "Delete domain",
        "DELETE_MSG": "<p>Are you sure you want delete this domain?</p><p>The domain will be deleted <strong>immediately.</strong></p>",
        "DELETE_LOADING": "Deleting domain...",
        "DELETE_SUCCESS": "Successfully deleted domain.",
        "DELETE_ERROR": "Failed to delete domain. Error: {{ error }}",
        "SAVING": "Saving domain...",
        "SAVE_ERROR": "Failed to save domain. Error: {{ error }}",
        "SAVE_SUCCESS": "Successfully saved domain.",
        "LOADING": "Loading domains...",
        "TAB_ABOUT": "About",
        "TAB_APPLICATIONS": "Applications",
        "TAB_AUTHENTICATION": "Authentication",
        "TAB_USERS": "Users",
        "NAME": "Domain Name",
        "NAME_PLACEHOLDER": "Domain e.g. www.google.com",
        "EMAIL_DOMAINS": "User Email Domains",
        "SETTINGS_CONFIG": "Config",
        "SETTINGS_INTERNALS": "Internals",
        "COPIED_EMAIL_DOMAIN": "Copied email domain to clipboard.",
        "SETTINGS_SAVED": "Successfully updated domain settings.",
        "APPLICATION_NEW": "New Application",
        "APPLICATION_EDIT": "Edit Application",
        "APPLICATION_REMOVE": "Remove Application",
        "FIELD_REDIRECT_URI": "Redirect URI",
        "FIELD_CLIENT_ID": "Client ID",
        "FIELD_CLIENT_SECRET": "Client Secret",
        "FIELD_TYPE": "Type",
        "FIELD_USER": "User",
        "FIELD_ROLE": "Role",
        "APPLICATIONS_EMPTY": "No applications for this domain",
        "COPY_SECRET": "Copy secret to Clipboard",
        "COPIED_SECRET": "Copied client secret to Clipboard",
        "VIEW_SECRET": "View Secret",
        "SECRET_HIDDEN": "Hidden",

        "USER_LIST_EMPTY": "No users associated with this domain",
        "NAME_REQUIRED": "A name is required for this domain",
        "DOMAIN_REQUIRED": "A valid domain is required",
        "LOGIN_URL": "Login URL",
        "LOGIN_URL_REQUIRED": "A valid login URL is required",
        "LOGOUT_URL": "Logout URL",
        "LOGOUT_URL_REQUIRED": "A valid logout URL is required",
        "CLIENT_ID": "Client ID",
        "CLIENT_SECRET": "Client Secret",

        "AUTHENTICATION_NEW": "New Auth Method",
        "AUTHENTICATION_EDIT": "Edit Auth Method",
        "AUTHENTICATION_REMOVE": "Remove Auth Method",
        "AUTHENTICATION_EMPTY": "No authentication methods for this domain",
        "AUTHENTICATION_SOURCE_TYPE": "Authentication Source Type",
        "AUTHENTICATION_SOURCE_SELECT": "Select authentication source...",
        "AUTHENTICATION_SAVE_ERROR": "Successfully updated authentication source",
        "AUTHENTICATION_SAVE_SUCCESS": "Failed to update authentication source. Error: {{ error }}",
        "AUTHENTICATION_NAME_REQUIRED": "An auth source name is required",

        "OAUTH_SITE": "Site",
        "OAUTH_SITE_PLACEHOLDER": "URL of the SSO provider",
        "OAUTH_SCOPES": "Scopes",
        "OAUTH_TOKEN_METHOD": "Token Method",
        "OAUTH_TOKEN_SCHEME": "Authentication Scheme",
        "OAUTH_SCHEME_BODY": "Request Body",
        "OAUTH_SCHEME_BASIC": "Basic Auth",
        "OAUTH_TOKEN_URL": "Token URL",
        "OAUTH_AUTHORISE_URL": "Authorise URL",
        "OAUTH_PROFILE_URL": "User Profile URL",
        "OAUTH_INFO_MAPPINGS": "Info Mappings",
        "OAUTH_AUTHORISE_PARAMS": "Authorise Parameters",
        "OAUTH_ENSURE_MATCHING": "Ensure Matching",

        "LDAP_HOST": "Host",
        "LDAP_HOST_REQUIRED": "A hostname is required",
        "LDAP_PORT": "Port number",
        "LDAP_USER_ID": "User ID Key",
        "LDAP_AUTH_METHOD": "Authentication Method",
        "LDAP_BASE": "Base",
        "LDAP_BASE_REQUIRED": "A base is required",
        "LDAP_BIND_DN": "Bind DN",
        "LDAP_FILTER": "Filter",

        "SAML_ISSUER": "Issuer",
        "SAML_ISSUER_REQUIRED": "An issuer is required",
        "SAML_IDP_TARGET_URL": "IdP Target URL",
        "SAML_IDP_TARGET_URL_REQUIRED": "A IdP Target URL is required",
        "SAML_NAME_ID_FORMAT": "Name Identifier Format",
        "SAML_NAME_ID_FORMAT_REQUIRED": "A Name Identifier Format is required",
        "SAML_REQUEST_ATTRIBUTES": "Request Attributes",
        "SAML_REQUEST_ATTRIBUTES_REQUIRED": "Request Attributes are required",
        "SAML_ASSERTION_URL": "Assertion URL",
        "SAML_ASSERTION_URL_REQUIRED": "An Assertion URL is required",
        "SAML_CERT_FINGERPRINT": "Certificate Fingerprint",
        "SAML_CERT_FULL": "Full Certificate",
        "SAML_UID_ATTRIBUTE": "UID Attribute",
        "SAML_ATTRIBUTE_SERVICE_NAME": "Attribute Service Name",
        "SAML_ATTRIBUTE_STATEMENTS": "Attribute Statements",
        "SAML_IDP_SSO_RUNTIME_PARAMS": "IdP SSO Runtime Params",
        "SAML_IDP_SLO_TARGET_URL": "IdP SLO Target URL",
        "SAML_SLO_DEFAULT_RELAY_STATE": "SLO Default Relay State",

        "APP_SKIP": "Skip Authorisation",
        "APP_NAME_REQUIRED": "An application name is required",
        "APP_PRESERVE_ID": "Preserve Client ID",
        "APP_CLIENT_ID": "Client ID",
        "APP_CLIENT_PLACEHOLDER": "MD5 Hash of the Redirect URI",
        "APP_SCOPES": "Access Scopes",
        "APP_REDIRECT_URL": "Redirect URL",
        "APP_REDIRECT_URL_REQUIRED": "A valid URL is required"
    },
    "ADMIN": {
        "TITLE": "Admin",
        "TAB_ABOUT": "About",
        "TAB_DATABASE": "Database",
        "TAB_CLUSTERS": "Clusters",
        "TAB_EDGES": "Edges",
        "TAB_INTERFACES": "Interfaces",
        "TAB_MQTT_BROKERS": "MQTT Brokers",
        "TAB_TENANT_CONFIG": "Staff Tenants",
        "TAB_RESOURCE_IMPORTS": "Resource Imports",
        "TAB_EXTENSIONS": "Extensions",
        "TAB_API_KEYS": "Application Keys",
        "TAB_CUSTOM_SCHEMAS": "Custom Schemas",
        "TAB_UPLOAD_STORAGE": "Data Stores",
        "TAB_UPLOADS_LIBRARY": "Uploads Library",
        "TAB_BUILD_JOBS": "Build Jobs",
        "APPLICATION_DETAILS": "Application Details",
        "VIEW_CHANGELOG": "View Changelog",
        "BUILD": "Build",
        "BUILT_AT": "Build",
        "BACKEND_SERVICES": "Backend Services",
        "PLATFORM": "Platform",
        "COPIED": "Copied {{ name }} to clipboard",
        "ALL_DOMAINS": "All Domains",
        "SELECT_DOMAIN": "Select domain...",

        "BACKEND_SERVICES_EMPTY": "No API service details available.",
        "BACKEND_SERVICES_ERROR": "Failed to load API details. Error: {{ error }}",
        "DATABASE_REINDEX": "Re-index",
        "DATABASE_REINDEX_MSG": "Re-index elasticsearch for the current state of the database",
        "DATABASE_BACKFILL": "Backfill",
        "DATABASE_BACKFILL_MSG": "Backfill elasticsearch with the current state of the database",

        "CLUSTERS": "PlaceOS Clusters",
        "CLUSTERS_VIEW_PROCESSES": "View Processes",
        "CLUSTERS_LIST_EMPTY": "No cluster details available",
        "CLUSTERS_CPU_USAGE": "CPU Usage",
        "CLUSTERS_MEMORY_USAGE": "Memory Usage",
        "CLUSTERS_CPU_CORES": "{{ count }} cores",
        "CLUSTER": "Cluster",
        "CLUSTERS_SEARCH_PROCESSES": "Search processes...",
        "CLUSTERS_FIELD_CPU_USAGE": "CPU %",
        "CLUSTERS_FIELD_MEMORY_USAGE": "Memory",
        "CLUSTERS_FIELD_INSTANCES": "Instances",
        "CLUSTER_PROCESSES_EMPTY": "No tasks running on this cluster",
        "CLUSTER_PROCESS_KILL": "Kill process",
        "CLUSTER_PROCESS_KILL_MSG": "<p>Are you sure you want kill the process for \"{{ id }}\"?</p><p>The process will be terminated <strong>immediately</strong>.The process may be restarted after a short while.</p>",
        "CLUSTER_PROCESS_KILL_LOADING": "Terminating process. Please wait...",
        "CLUSTER_PROCESS_KILL_ERROR": "Failed to kill process. Error: {{ error }}",

        "EDGE_HEADER": "PlaceOS Edge Nodes",
        "EDGE_ADD": "Add Edge node",
        "EDGE_NEW": "New Edge node",
        "EDGE_EDIT": "Edit Edge node",
        "EDGE_SAVING": "Saving edge node...",
        "EDGE_REMOVE": "Remove Edge node",
        "EDGE_ERROR": "Failed to update edge node.",
        "EDGE_NAME_PLACEHOLDER": "Name of the Edge node",
        "EDGE_DESCRIPTION_PLACEHOLDER": "Description of the Edge node",
        "EDGE_NAME_REQUIRED": "An Edge name is required",
        "EDGE_NEW_SUCCESS": "Successfully updated edge.",
        "EDGE_EDIT_SUCCESS": "Successfully added new edge. Please make sure to save the API key as you will not be able to view it again in the future.",
        "EDGE_LIST_EMPTY": "No edges available on this cluster",
        "INTERFACES_HEADER": "PlaceOS Interfaces",
        "INTERFACES_COMMIT_EMPTY": "No commit hash",

        "BROKERS_HEADER": "MQTT Brokers",
        "BROKERS_ADD": "Add MQTT Broker",
        "BROKERS_NEW": "New MQTT Broker",
        "BROKERS_EDIT": "New MQTT Broker",
        "BROKERS_REMOVE": "Remove MQTT Broker",
        "BROKER_LIST_EMPTY": "No MQTT Brokers",
        "BROKERS_FIELD_HOST": "Host",
        "BROKERS_FIELD_PORT": "Port",
        "BROKERS_FIELD_TLS": "TLS",
        "BROKERS_FIELD_FILTERS": "Filters",
        "BROKERS_FIELD_AUTH_TYPE": "Auth Type",
        "BROKERS_AUTH_TYPE_CERT": " Certificate",
        "BROKERS_AUTH_TYPE_PASS": "User Password",
        "BROKERS_AUTH_TYPE_NONE": "No Auth",
        "BROKERS_FILTERS_EMPTY": "No filters",
        "BROKERS_NAME_REQUIRED": "A broker name is required",
        "BROKERS_HOST_REQUIRED": "A broker name is required",
        "BROKERS_PORT_REQUIRED": "A valid port number between 1 - 65535 is required",
        "BROKERS_USERNAME": "Username",
        "BROKERS_USERNAME_REQUIRED": "A valid username is required",
        "BROKERS_PASSWORD": "Password",
        "BROKERS_PASSWORD_REQUIRED": "A valid password is required ",
        "BROKERS_CERT": "Certificate",
        "BROKERS_CERT_REQUIRED": "A valid certificate is required ",

        "TENANTS_HEADER": "Staff API Tenants",
        "TENANTS_ADD": "Add Tenant",
        "TENANTS_NEW": "New Tenant",
        "TENANTS_EDIT": "Edit Tenant",
        "TENANTS_REMOVE": "Remove Tenant",
        "TENANTS_PLATFORM": "Platform",
        "TENANTS_SECRET_EXPIRY": "Expires",
        "TENANTS_EMPTY": "No tenants for the selected domain",
        "TENANTS_EDIT_BOOKING_LIMITS": "Edit Booking Limits",
        "TENANTS_FIELD_NAME": "Tenant name",
        "TENANTS_NAME_ERROR": "A tenant name is required",
        "TENANTS_EMAIL_DOMAIN": "Email Domain",
        "TENANTS_EMAIL_PLACEHOLDER": "Domain of user's email for this tenant",
        "TENANTS_EMAIL_ERROR": "A domain is required",
        "TENANTS_EARLY_CHECKIN": "Early Check-in before Meeting",
        "TENANTS_SERVICE_ACCOUNT": "Service Account",
        "TENANTS_SERVICE_ACCOUNT_ERROR": "Service account should be a valid email address",
        "TENANTS_DELEGATED": "Delegated",
        "TENANTS_ITEM_REQUIRED": "A {{ name }} is required",
        "TENANTS_CONFIG_OUTLOOK": "Configure Outlook Plugin",
        "TENANTS_APP_ID": "Outlook App ID",
        "TENANTS_APP_ID_REQUIRED": "An application ID is required",
        "TENANTS_APP_DOMAIN": "Outlook App Domain",
        "TENANTS_APP_DOMAIN_REQUIRED": "An application domain is required",
        "TENANTS_APP_RESOURCE": "Outlook App Resource",
        "TENANTS_APP_RESOURCE_REQUIRED": "An application resource is required",
        "TENANTS_SOURCE_LOCATION": "Outlook Source Location",
        "TENANTS_SOURCE_LOCATION_REQUIRED": "A source location is required",
        "TENANTS_BASE_PATH": "Outlook Base Path",
        "TENANTS_BASE_PATH_REQUIRED": "Base path to the application root folder is required",
        "TENANTS_BOOKING_LIMITS": "Booking Limits",
        "TENANTS_MINUTES": "{{ minute }} minutes",
        "TENANTS_HOURS": "{{ hour }} hours",
        "TENANTS_MINUTES_HOURS": "{{ hour }} hours {{ minute }} minutes",
        "TENANTS_SAVE_ERROR": "Failed to update tenant.",
        "TENANTS_SAVE_SUCCESS": "Successfully updated tenant.",

        "RESOURCE_IMPORTS_HEADER": "Resource Imports",
        "RESOURCE_IMPORTS_ALL": "Import All",
        "RESOURCE_IMPORTS_IMPORTED": "Imported",
        "RESOURCE_IMPORTS_IMPORT": "Import resource",
        "RESOURCE_IMPORTS_VIEW": "View System",
        "RESOURCE_IMPORTS_EMPTY": "No resource available to import on selected domain",
        "RESOURCE_IMPORTS_ALL_WARNING": "All resources are already imported.",
        "RESOURCE_IMPORTS_ALL_TITLE": "Import missing resources?",
        "RESOURCE_IMPORTS_ALL_MSG": "Are you sure you want to import the following {{ count }} resources?",
        "RESOURCE_IMPORTS_ALL_LOADING": "Importing missing resources...",
        "RESOURCE_IMPORTS_ALL_SUCCESS": "Successfully imported {{ count }} resources.",
        "RESOURCE_IMPORTS_SUCCESS": "Successfully imported resource {{ name }}.",

        "EXTENSIONS_HEADER": "Extensions",
        "EXTENSIONS_ADD": "Add Extension",
        "EXTENSIONS_NEW": "New Extension",
        "EXTENSIONS_EDIT": "Edit Extension",
        "EXTENSIONS_REMOVE": "Remove Extension",
        "EXTENSIONS_SAVING": "Saving extension...",
        "EXTENSIONS_NOTICE": "Note: Backoffice requires a full page refresh for extension changes and additions to apply",
        "EXTENSIONS_LIST_EMPTY": "No extensions available for the selected domain",
        "EXTENSIONS_FIELD_CHECKS": "Checks",
        "EXTENSIONS_FIELD_CONDITIONS": "Conditions",
        "EXTENSIONS_FIELD_TYPE": "Type",
        "EXTENSIONS_FIELD_NAME": "Extension Display Name",
        "EXTENSIONS_NAME_REQUIRED": "A valid name is required",
        "EXTENSIONS_FIELD_TAB": "Tab Name",
        "EXTENSIONS_FIELD_URL": "URL",
        "EXTENSIONS_URL_REQUIRED": "A valid URL is required",
        "EXTENSIONS_CONDITION_ADD": "Add Condition",
        "EXTENSIONS_CONDITION_VALUE": "Condition value",
        "EXTENSIONS_CONDITION_OP": "Operation",
        "EXTENSIONS_CONDITION_FIELD": "Condition Field",

        "APP_KEYS_HEADER": "PlaceOS Application Keys",
        "APP_KEYS_ADD": "Add App Key",
        "APP_KEYS_NEW": "New Application Key",
        "APP_KEYS_REMOVE": "Remove Application Key",
        "APP_KEYS_LAST_DETAILS": "Last App Key Details",
        "APP_KEYS_FIELD_SCOPES": "Scopes",
        "APP_KEYS_FIELD_PERMISSIONS": "Access",
        "APP_KEYS_SCOPES_REQUIRE": "At least one scope is required",
        "APP_KEYS_NAME_REQUIRED": "A name is required",
        "APP_KEYS_PERMISSIONS_EMPTY": "No permissions",
        "APP_KEYS_COPIED": "Copied Application Key to clipboard",
        "APP_KEYS_LIST_EMPTY": "No application keys configured for this domain",
        "PERMISSIONS": "Permissions",
        "PERMISSIONS_ADMIN": "Admin",
        "PERMISSIONS_SUPPORT": "Support",
        "PERMISSIONS_USER": "User",
        "PERMISSIONS_NONE": "None",

        "SCHEMA_HEADER": "Custom Schemas",
        "SCHEMA_SELECT": "Select Schema...",
        "SCHEMA_ADD": "Add Schema",
        "SCHEMA_NAME": "Schema Name",
        "SCHEMA_NEW": "New Schema",
        "SCHEMA_SELECT_MSG": "Select a schema to edit",

        "STORAGE_HEADER": "PlaceOS Data Stores",
        "STORAGE_ADD": "Add Provider",
        "STORAGE_NEW": "New Storage Provider",
        "STORAGE_EDIT": "Edit Storage Provider",
        "STORAGE_REMOVE": "Remove Storage Provider",
        "STORAGE_REMOVE_TITLE": "Remove Storage Provider",
        "STORAGE_REMOVE_MSG": "Are you sure you want to remove the storage provider for {{ type }} {{name}}?",
        "STORAGE_REMOVE_LOADING": "Removing storage provider...",
        "STORAGE_LOADING": "Loading storage providers...",
        "STORAGE_FIELD_TYPE": "Type",
        "STORAGE_FIELD_REGION": "Region",
        "STORAGE_LIST_EMPTY": "No storage providers for {{ item }} domain",
        "STORAGE_SAVE_SUCCESS": "Successfully saved storage provider.",
        "STORAGE_SAVE_ERROR": "Failed to save storage provider.",
        "STORAGE_BUCKET_NAME_LABEL": "Bucket Name",
        "STORAGE_BUCKET_NAME_REQUIRED": "Bucket Name is required",
        "STORAGE_REGION_LABEL": "Region",
        "STORAGE_REGION_REQUIRED": "Region is required",
        "STORAGE_ENDPOINT_LABEL": "Endpoint",
        "STORAGE_ACCESS_KEY_LABEL": "Access Key",
        "STORAGE_ACCESS_KEY_REQUIRED": "Access Key is required",
        "STORAGE_SECRET_LABEL": "Access Secret",
        "STORAGE_SECRET_REQUIRED": "Access Secret is required",
        "STORAGE_ALLOWED_EXTENSIONS_LABEL": "Allowed file extensions",
        "STORAGE_ALLOWED_MIME_LABEL": "Allowed file MIME types",
        "STORAGE_ALLOW_ALL_EXTENSIONS": "Allow all file extensions",
        "STORAGE_ALLOW_ALL_MIME": "Allow all MIME types",

        "UPLOADS_LIB_HEADER": "Uploads Library",
        "UPLOADS_LIB_DOWNLOAD": "Download Upload",
        "UPLOADS_LIB_DOWNLOAD_ERROR": "Failed to download upload. Error: {{ error }}",
        "UPLOADS_LIB_VIEW": "View Upload",
        "UPLOADS_LIB_REMOVE": "Remove Upload",
        "UPLOADS_LIB_REMOVE_MSG": "<p>Are you sure you want remove the upload \"{{ filename }}\"?</p><p>The upload will be deleted <strong>immediately</strong>.</p>",
        "UPLOADS_LIB_REMOVE_LOADING": "Removing upload...",
        "UPLOADS_LIB_FIELD_TYPE": "File Type",
        "UPLOADS_LIB_FIELD_SIZE": "Size",
        "UPLOADS_LIB_LIST_EMPTY": "No uploads for the selected domain",

        "BUILD_LIST_HEADER": "Build Service Jobs",
        "BUILD_LIST_EMPTY": "No build jobs available to monitor",
        "BUILD_LIST_REMOVE": "Cancel Build Job",
        "BUILD_LIST_REMOVE_MSG": "Are you sure you wish to cancel the build job for {{ driver }} from {{ repo }}?",
        "BUILD_LIST_REMOVE_LOADING": "Cancelling build job...",
        "BUILD_LIST_REMOVE_ERROR": "Failed to cancel build job. Error: {{ error }}",
        "BUILD_LIST_REMOVE_SUCCESS": "Successfully cancelled build job."
    }
}
